import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AdminContext } from '../../context/AdminContext';
import { DateTimeField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function AdvertisementEditDialog({ advertisement, forReport, dialogOpen, handleClose, handleFormSubmit }) {

    const { token } = useContext(AdminContext);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCategories`, { headers: { "token": token } })
                .catch((err) => {
                    console.log("err: " + err);
                    setCategories([]);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        setCategories(response.data.categories);
                    }
                });
        }
        fetchCategories();
    }, [])

    var initialValues = {
        title: advertisement?.title ? advertisement?.title : "",
        description: advertisement?.description ? advertisement?.description : "",
        is_active: advertisement?.is_active && advertisement?.is_active == 1 ? true : false,
        category_1_id: advertisement?.category_1_id ? advertisement?.category_1_id : "",
        category_2_id: advertisement?.category_2_id ? advertisement?.category_2_id : "",
        expiration_date: advertisement?.expiration_date ? dayjs(advertisement?.expiration_date) : null,
        advertisement_contents: advertisement?.advertisement_contents ? [...(advertisement?.advertisement_contents)] : []
    };

    var validationObject = {
        title: yup.string().required("zorunlu"),
        description: yup.string().required("zorunlu"),
        is_active: yup.boolean().required("zorunlu"),
        category_1_id: yup.number().required("zorunlu"),
        category_2_id: yup.number().nullable(),
        expiration_date: yup.date().required("zorunlu"),
        advertisement_contents: yup.array().of(yup.object().shape({
            content_link: yup.string().required("zorunlu"),
            content_path: yup.string().required("zorunlu"),
            is_photo: yup.boolean().required("zorunlu")
        })).required("zorunlu")
    }

    const advertisementSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            maxWidth="md"
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    İlanı Düzenle
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={advertisementSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" my="25px">
                                    <Box display="grid" columnGap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" gridColumn="span 4">
                                        {
                                            values.advertisement_contents.map((content, index) =>
                                                <Box key={content.id} gridColumn={"span 1"} my={0}>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        sx={{
                                                            position: "relative",
                                                            top: "40px",
                                                            left: "15px",
                                                            zIndex: 1
                                                        }}
                                                        onClick={() => {
                                                            let newContents = values.advertisement_contents;
                                                            newContents.splice(index, 1);
                                                            setFieldValue("advertisement_contents", newContents);
                                                        }}>
                                                        Sil
                                                    </Button>
                                                    <Box
                                                        my={0}
                                                        component={content.is_photo ? "img" : "video"}
                                                        controls
                                                        borderRadius="12px"
                                                        src={content.content_link}
                                                        alt={`advertisement-sm-${index}`}
                                                        sx={{
                                                            objectFit: "cover",
                                                        }}
                                                        width={"100%"} />
                                                </Box>


                                            )
                                        }
                                    </Box>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Başlık"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    {categories.length > 0 &&
                                        <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                                            <InputLabel id="filter-label">Kategori - 1</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Kategori - 1"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"category_1_id"}
                                                value={values.category_1_id}
                                                error={!!touched.category_1_id && !!errors.category_1_id}
                                            //helperText={touched.category_1_id && errors.category_1_id}
                                            >
                                                {categories.filter((category) => category.id !== values.category_2_id).map((category) => {
                                                    return <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    }
                                    {categories.length > 0 &&
                                        <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                                            <InputLabel id="filter-label">Kategori - 2</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Kategori - 2"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"category_2_id"}
                                                value={values.category_2_id}
                                                error={!!touched.category_2_id && !!errors.category_2_id}
                                            //helperText={touched.category_2_id && errors.category_2_id}
                                            >
                                                <MenuItem value={""}>Yok</MenuItem>
                                                {categories.filter((category) => category.id !== values.category_1_id).map((category) => {
                                                    return <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    }
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.is_active}
                                                size="small"
                                            />}
                                        variant="filled"
                                        label="Aktif İlan"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"is_active"}
                                        value={values.is_active}
                                        sx={{ gridColumn: "span 1" }} />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Açıklama"
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"description"}
                                        value={values.description}
                                        error={!!touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimeField
                                            fullWidth
                                            variant="filled"
                                            label="Bitiş Tarihi (Ay/Gün/Yıl Saat:Dakika)"
                                            format="L HH:mm"
                                            onBlur={handleBlur}
                                            onChange={(date) => setFieldValue("expiration_date", date)}
                                            name={"expiration_date"}
                                            value={values.expiration_date}
                                            error={!!touched.expiration_date && !!errors.expiration_date}
                                            helperText={touched.expiration_date && errors.expiration_date}
                                            sx={{ gridColumn: "span 2", marginTop: "20px" }} />
                                    </LocalizationProvider>
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5' color="white">{forReport ? "Kaydet ve Şikayeti Sil" : "Kaydet"}</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default AdvertisementEditDialog