import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';

function FaqDialog({ faq, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        title: faq?.title ? faq?.title : "",
        answer: faq?.answer ? faq?.answer : "",
        translations: faq?.translations && faq?.translations.length > 0 ? faq?.translations : [
            { language: "de", title_translation: "", answer_translation: "" }
        ]
    };

    var validationObject = {
        title: yup.string().required("zorunlu"),
        answer: yup.string().required("zorunlu"),
        translations: yup.array().of(yup.object().shape({
            language: yup.string().required("zorunlu"),
            title_translation: yup.string().required("zorunlu"),
            answer_translation: yup.string().required("zorunlu"),
        }).required("zorunlu")).required("zorunlu")
    }

    const faqSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {faq ? "SSS'i Düzenle" : "Yeni SSS Ekle"}
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={faqSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Soru(Başlık) (en)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Cevap (en)"
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"answer"}
                                        value={values.answer}
                                        error={!!touched.answer && !!errors.answer}
                                        helperText={touched.answer && errors.answer}
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                    {values.translations.map((translation, i) =>
                                        <Box key={`translation-${i}`} mt={"15px"} >
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label={`Soru(Başlık)* (${translation.language})`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"translations[" + i + "].title_translation"}
                                                value={values.translations[i].title_translation}
                                                error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.title_translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.title_translation}
                                                helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.title_translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.title_translation}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label={`Cevap* (${translation.language})`}
                                                multiline
                                                minRows={3}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"translations[" + i + "].answer_translation"}
                                                value={values.translations[i].answer_translation}
                                                error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.answer_translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.answer_translation}
                                                helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.answer_translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.answer_translation}
                                                sx={{ gridColumn: "span 1", mt: "15px" }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default FaqDialog