import { Route, Routes } from "react-router-dom";
import Sidebar from "./global/Sidebar";
import { useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useContext } from "react";
import { AdminContext } from "./context/AdminContext";
import LoginPage from "./pages/LoginPage";
import UsersPage from "./pages/UsersPage";
import Topbar from "./global/Topbar";
import AdminsPage from "./pages/AdminsPage";
import UserDetailsPage from "./pages/UsersPage/UserDeatilsPage";
import ReportsPage from "./pages/ReportsPage";
import ContactMessagesPage from "./pages/ContactMessagesPage";
import CategoriesPage from "./pages/CategoriesPage";
import FaqPage from "./pages/FaqPage";

function App() {
  const { admin, token, isLoading } = useContext(AdminContext);
  const theme = useMode();
  if (admin && token) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Topbar />
        <div className="app">
          <Sidebar />
          <main className="content pl80">
            <Routes>
              {admin.users_perm === 1 && <Route exact path="/" element={<UsersPage />} />}
              {admin.users_perm === 1 && <Route exact path="/users" element={<UsersPage />} />}
              {admin.users_perm === 1 && <Route exact path="/users/:userId" element={<UserDetailsPage />} />}
              {admin.categories_perm === 1 && <Route exact path="/categories" element={<CategoriesPage />} />}
              {admin.reports_perm === 1 && <Route exact path="/reports" element={<ReportsPage />} />}
              {admin.contact_messages_perm === 1 && <Route exact path="/contactMessages" element={<ContactMessagesPage />} />}
              {admin.faqs_perm === 1 && <Route exact path="/faqs" element={<FaqPage />} />}
              {admin.admins_perm === 1 && <Route exact path="/admins" element={<AdminsPage />} />}
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    );
  } else if (!isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content" >
            <LoginPage />
          </main>
        </div>
      </ThemeProvider>
    )
  }
}

export default App;
