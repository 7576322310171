import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import AdvertisementReportBox from '../../components/Report/AdvertisementReportBox';
import UserReportBox from '../../components/Report/UserReportBox';
import AlertDialogSlide from '../../components/AlertDialogSlide';

function ReportsPage() {
    const { token } = useContext(AdminContext);
    const [reportType, setReportType] = useState("advertisement");
    const [reports, setReports] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [reportToRemove, setReportToRemove] = useState(null);

    useEffect(() => {
        fetchReports();
    }, [startIndex]);

    useEffect(() => {
        if (reportToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [reportToRemove]);

    useEffect(() => {
        setReports([]);
        if (startIndex === 0) {
            fetchReports();
        } else {
            setStartIndex(0);
        }
    }, [reportType]);

    const fetchReports = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getReports?userReports=${reportType === "user" ? 1 : 0}&startIndex=${startIndex}&limit=20`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
                setReports([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.reports && response.data.reports.length === 20);
                    if (startIndex === 0) {
                        setReports(response.data.reports);
                    } else {
                        setReports((prev) => [...prev, ...response.data.reports]);
                    }
                }
            });
    }

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    }
    const clickPositiveAnswer = () => {
        removeReport(reportToRemove);
    }
    const clickNegativeAnswer = () => {
    }

    const removeReport = (report) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteReport?reportId=${report.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setReportToRemove(null);
                setRemoveDialogOpen(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = reports.findIndex(({ id }) => id === report.id);
                    if (ix >= 0) {
                        reports.splice(ix, 1);
                        setReports(Array.from(reports));
                    }
                    setReportToRemove(null);
                    setRemoveDialogOpen(false);
                }
            });
    }

    const onAdvertisementRemoved = (report) => {
        var ix = reports.findIndex(({ id }) => id === report.id);
        if (ix >= 0) {
            reports.splice(ix, 1);
            setReports(Array.from(reports));
        }
    }

    return (
        <Box sx={{ p: "75px" }}>
            {
                reportToRemove &&
                <AlertDialogSlide
                    open={removeDialogOpen} handleClose={() => {
                        handleRemoveDialogClose();
                        setReportToRemove(null);
                    }}
                    clickPositiveAnswer={() => clickPositiveAnswer()} clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                    title={"Bu şikayeti silmek istediğinize emin misiniz?"}
                    description={"İlgili şikayet işlem yapmadan silinir ve bu işlem geri alınamaz."}
                />
            }
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Şikayetler" subtitle="Bu sayfadan şikayetleri görüntüleyebilir ve işlem yapabilirsiniz." />
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel id="filter-type">Şikayet Tipi</InputLabel>
                        <Select
                            labelId="filter-type"
                            label="Şikayet Tipi"
                            value={reportType}
                            onChange={(e) => {
                                setReportType(e.target.value ? e.target.value : "advertisement");
                            }}
                        >
                            <MenuItem value={"advertisement"}>İlan</MenuItem>
                            <MenuItem value={"user"}>Kullanıcı</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={reports.length}
                    next={() => {
                        setStartIndex(reports.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Tüm şikayetleri görüntülüyorsunuz.</b>
                        </p>
                    }
                >
                    <Box m="10px 0 0 0" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                        {reports.map(report => {
                            if (report.advertisement_id) {
                                return (
                                    <AdvertisementReportBox
                                        key={report.id}
                                        report={report}
                                        onDeleteButtonClick={() => setReportToRemove(report)}
                                        removeReport={(r) => removeReport(r)}
                                        onAdvertisementRemoved={(r) => onAdvertisementRemoved(r)}
                                    />
                                )
                            } else {
                                return (
                                    <UserReportBox
                                        report={report}
                                        onDeleteButtonClick={() => setReportToRemove(report)}
                                    />
                                )
                            }
                        }
                        )}
                    </Box>
                </InfiniteScroll>
            </Box>

        </Box>
    )
}

export default ReportsPage