import { Box } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import ContactMessageBox from './ContactMessageBox';

function ContactMessagesPage() {
    const { token } = useContext(AdminContext);
    const [contactMessages, setContactMessages] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [messageToRemove, setMessageToRemove] = useState(null);

    useEffect(() => {
        fetchContactMessages();
    }, [startIndex]);

    useEffect(() => {
        if (messageToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [messageToRemove]);

    const fetchContactMessages = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getContactMessages?startIndex=${startIndex}&limit=20`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
                setContactMessages([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.contactMessages && response.data.contactMessages.length === 20);
                    if (startIndex === 0) {
                        setContactMessages(response.data.contactMessages);
                    } else {
                        setContactMessages((prev) => [...prev, ...response.data.contactMessages]);
                    }
                }
            });
    }

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    }
    const clickPositiveAnswer = () => {
        removeContactMessage(messageToRemove);
    }
    const clickNegativeAnswer = () => {
    }

    const removeContactMessage = (contactMessage) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteContactMessage?contactMessageId=${contactMessage.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setMessageToRemove(null);
                setRemoveDialogOpen(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = contactMessages.findIndex(({ id }) => id === contactMessage.id);
                    if (ix >= 0) {
                        contactMessages.splice(ix, 1);
                        setContactMessages(Array.from(contactMessages));
                    }
                    setMessageToRemove(null);
                    setRemoveDialogOpen(false);
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            {
                messageToRemove &&
                <AlertDialogSlide
                    open={removeDialogOpen} handleClose={() => {
                        handleRemoveDialogClose();
                        setMessageToRemove(null);
                    }}
                    clickPositiveAnswer={() => clickPositiveAnswer()} clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                    title={"Bu mesaji silmek istediğinize emin misiniz?"}
                    description={"İlgili destek mesajı silinir ve bu işlem geri alınamaz."}
                />
            }
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Destek Mesajları" subtitle="Bu sayfadan destek mesajlarını görüntüleyebilir ve işlem yapabilirsiniz." />
                </Box>

            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={contactMessages.length}
                    next={() => {
                        setStartIndex(contactMessages.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Tüm mesajları görüntülüyorsunuz.</b>
                        </p>
                    }
                >
                    <Box m="10px 0 0 0" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                        {contactMessages.map(message =>
                            <ContactMessageBox
                                key={message.id}
                                contactMessage={message}
                                onDeleteButtonClick={() => setMessageToRemove(message)}
                            />
                        )}
                    </Box>
                </InfiniteScroll>
            </Box>

        </Box>
    )
}

export default ContactMessagesPage