import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';

function CommentEditDialog({ comment, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        comment: comment?.comment ? comment?.comment : "",
        point: comment?.point ? comment?.point : 5
    };

    var validationObject = {
        comment: yup.string().required("zorunlu"),
        point: yup.number().min(1, "En az 1 puan verilebilir.").max(5, "En fazla 5 puan verilebilir.").required("zorunlu")
    }

    const commentSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            maxWidth="sm"
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    Yorumu Düzenle
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={commentSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Puan"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"point"}
                                        value={values.point}
                                        error={!!touched.point && !!errors.point}
                                        helperText={touched.point && errors.point}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Yorum"
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"comment"}
                                        value={values.comment}
                                        error={!!touched.comment && !!errors.comment}
                                        helperText={touched.comment && errors.comment}
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" >
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default CommentEditDialog