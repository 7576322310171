import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { tokens } from '../../theme';

function ReportBox({ report, onDeleteButtonClick }) {
    const colors = tokens();
    return (
        <Box display="flex" justifyContent="space-between" sx={{ border: "1px solid " + colors.grey[500], padding: "10px 15px 10px 15px", borderRadius: "20px 20px 0 0" }}>
            <Box >
                <Box display="flex" gap="10px" alignItems="center">
                    <Box component="img" src={report.origin_user_profile_photo_link} alt={report.origin_user_name} sx={{ objectFit: "cover", width: "50px", height: "50px", borderRadius: "25px" }} />
                    <Box>
                        <Typography variant="h6">Raporlayan</Typography>
                        <Typography variant="h4">{report.origin_user_name} {report.origin_user_surname}</Typography>
                    </Box>
                </Box>
                <Typography mt="10px">{report.message}</Typography>
            </Box>
            <Box>
                <Button variant="contained" color="error" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => onDeleteButtonClick()} >Şikayeti Sil</Button>
            </Box>
        </Box>

    )
}

export default ReportBox