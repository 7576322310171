import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { tokens } from '../../../theme';

function CategoryBox({ category, onEditButtonClick, onDeleteButtonClick }) {
    const colors = tokens();
    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ gridColumn: "span 1", border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box padding="10px" display="flex" rowGap="10px" flexDirection="column" justifyContent="space-between" alignItems="center" >
                <Box component="img" src={category.content_link} alt={category.name} sx={{ objectFit: "cover", width: "50px", height: "50px", borderRadius: "25px" }} />
                <Typography variant="h3">{category.name}</Typography>
            </Box>
            <Box display="flex" gap="10px" py="20px" justifyContent="space-evenly">
                <Button variant="contained" color="info" sx={{ width: "35%", borderRadius: "12px" }} onClick={() => onEditButtonClick()}>Düzenle</Button>
                <Button variant="contained" color="error" sx={{ width: "35%", borderRadius: "12px" }} onClick={() => onDeleteButtonClick()}>Sil</Button>
            </Box>
        </Box>
    )
}

export default CategoryBox