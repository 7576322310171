import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react'
import Header from '../../../global/Header';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import { tokens } from '../../../theme';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AlertDialogSlide from '../../../components/AlertDialogSlide';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AdminContext } from '../../../context/AdminContext';
import AdvertisementBox from '../../../components/Advertisement/AdvertisementBox';
import AdvertisementDetailsDialog from '../../../components/Advertisement/AdvertisementDetailsDialog';
import AdvertisementEditDialog from '../../../components/Advertisement/AdvertisementEditDialog';
import CommentBox from '../../../components/Comment/CommentBox';
import CommentEditDialog from '../../../components/Comment/CommentEditDialog';
import NotificationDialog from '../../../components/NotificationDialog';

const getUserAge = (birthDate) => {
    var today = new Date();
    var bd = new Date(birthDate);
    var age = today.getFullYear() - bd.getFullYear();
    var m = today.getMonth() - bd.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < bd.getDate())) {
        age--;
    }
    return age;
};

function UserDetailsPage() {
    const theme = useTheme();
    const colors = tokens();
    const { token } = useContext(AdminContext);
    const { userId } = useParams();

    const [user, setUser] = useState(null);
    const [comments, setComments] = useState([]);
    const [advertisements, setAdvertisements] = useState([]);
    const [hasMoreComments, setHasMoreComments] = useState(true);
    const [hasMoreAdvertisements, setHasMoreAdvertisements] = useState(true);

    const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);

    const [advertisementEvent, setAdvertisementEvent] = useState({ advertisement: null, type: null, fetched: false });
    const [advertisementDetailsDialogOpen, setAdvertisementDetailsDialogOpen] = useState(false);
    const [advertisementEditDialogOpen, setAdvertisementEditDialogOpen] = useState(false);

    const [commentEvent, setCommentEvent] = useState({ comment: null, type: null });
    const [commentEditDialogOpen, setCommentEditDialogOpen] = useState(false);

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [openAdvertisementDeletionAlertDialog, setOpenAdvertisementDeletionAlertDialog] = useState(false);
    const [openCommentDeletionAlertDialog, setOpenCommentDeletionAlertDialog] = useState(false);

    const handleAlertDialogClose = (type) => {
        if (type === "user") {
            setOpenAlertDialog(false);
        } else if (type === "advertisement") {
            setOpenAdvertisementDeletionAlertDialog(false);
        } else if (type === "comment") {
            setOpenCommentDeletionAlertDialog(false);
        }
    }
    const clickPositiveAnswer = (type) => {
        if (type === "user") {
            axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteUserWithoutApple?id=${user.id}&firebase_id=${user.firebase_id}`, { headers: { "token": token } })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        window.location.href = "/users";
                    }
                });
        } else if (type === "advertisement") {
            axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteAdvertisement?advertisementId=${advertisementEvent.advertisement.id}`, { headers: { "token": token } })
                .catch((err) => {
                    console.log("err: " + err);
                    setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
                    setOpenAdvertisementDeletionAlertDialog(false);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        var ix = advertisements.findIndex(({ id }) => id === advertisementEvent.advertisement.id);
                        if (ix >= 0) {
                            advertisements.splice(ix, 1);
                            setAdvertisements(Array.from(advertisements));
                        }
                        setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
                        setOpenAdvertisementDeletionAlertDialog(false);
                    }
                });
        } else if (type === "comment") {
            axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteComment?reviewId=${commentEvent.comment.id}`, { headers: { "token": token } })
                .catch((err) => {
                    console.log("err: " + err);
                    setCommentEvent({ comment: null, type: null });
                    setOpenCommentDeletionAlertDialog(false);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        var ix = comments.findIndex(({ id }) => id === commentEvent.comment.id);
                        if (ix >= 0) {
                            comments.splice(ix, 1);
                            setComments(Array.from(comments));
                        }
                        setCommentEvent({ comment: null, type: null });
                        setOpenCommentDeletionAlertDialog(false);
                    }
                });
        }
    }
    const clickNegativeAnswer = () => { }

    useEffect(() => {
        fetchUser();
        fetchAdvertisements(0);
        fetchComments(0);
    }, []);

    useEffect(() => {
        const fetchAdvertisementDeatils = () => {
            axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getAdvertisementWithDetails?advertisementId=${advertisementEvent.advertisement.id}`)
                .catch((err) => {
                    console.log("err: " + err);
                    setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        setAdvertisementEvent((prev) => { return { advertisement: response.data.advertisement, type: prev.type, fetched: true } });
                    }
                });
        }
        if (advertisementEvent?.advertisement && advertisementEvent.type === "info") {
            if (!advertisementEvent.fetched) {
                fetchAdvertisementDeatils();
            } else {
                setAdvertisementDetailsDialogOpen(true);
            }
        } else if (advertisementEvent?.advertisement && advertisementEvent.type === "delete") {
            setOpenAdvertisementDeletionAlertDialog(true);
        } else if (advertisementEvent?.advertisement && advertisementEvent.type === "edit") {
            if (!advertisementEvent.fetched) {
                fetchAdvertisementDeatils();
            } else {
                setAdvertisementEditDialogOpen(true);
            }
        }
    }, [advertisementEvent]);

    useEffect(() => {
        if (commentEvent?.comment && commentEvent.type === "delete") {
            setOpenCommentDeletionAlertDialog(true);
        } else if (commentEvent?.comment && commentEvent.type === "edit") {
            setCommentEditDialogOpen(true);
        }
    }, [commentEvent]);

    const advertisementDialogHandleClose = (type) => {
        setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
        if (type === "info") {
            setAdvertisementDetailsDialogOpen(false);
        } else if (type === "edit") {
            setAdvertisementEditDialogOpen(false);
        }
    }

    const commentEditDialogHandleClose = () => {
        setCommentEvent({ comment: null, type: null });
        setCommentEditDialogOpen(false);
    }

    const fetchUser = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUser?userId=${userId}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUser(response.data.user);
                }
            });
    }

    const fetchAdvertisements = (startIndex) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/getAdvertisements`, {
            userId: userId,
            adminRequested: 1,
            onlyOwneds: 1,
            orderType: "DESC",
            startIndex: startIndex,
            limit: 10
        }, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setHasMoreAdvertisements(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMoreAdvertisements(response.data.advertisements && response.data.advertisements.length === 10);
                    if (startIndex === 0) {
                        setAdvertisements([...response.data.advertisements]);
                    } else {
                        setAdvertisements((prev) => [...prev, ...response.data.advertisements]);
                    }
                }
            });
    }

    const fetchComments = (startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUserMadeComments?userId=${userId}&startIndex=${startIndex}&limit=10`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setHasMoreComments(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMoreComments(response.data.comments && response.data.comments.length === 10);
                    if (startIndex === 0) {
                        setComments([...response.data.comments]);
                    } else {
                        setComments((prev) => [...prev, ...response.data.comments]);
                    }
                }
            });
    }

    const updateAdvertisement = async (values) => {
        values = { ...values, expiration_date: values.expiration_date.toISOString() }
        if (values?.category_2_id === "") values.category_2_id = null;
        if (values?.advertisement_contents?.length !== advertisementEvent.advertisement.advertisement_contents.length) {
            console.log("values.advertisement_contents: " + values.advertisement_contents);
            var advertisementContents = [];
            values.advertisement_contents.forEach((content, index) => {
                advertisementContents.push({ content_path: content.content_path, is_photo: content.is_photo, position: index, advertisement_id: advertisementEvent.advertisement.id });
            });
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdvertisementContents?advertisementId=${advertisementEvent.advertisement.id}`, {
                advertisementContents: advertisementContents
            }, {
                headers: {
                    "token": token
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {

                    }
                });
        }
        delete values.advertisement_contents;
        if (values.is_active === 1 && advertisementEvent.advertisement.is_active === 0) {
            values.expiration_date = (() => { var tempdate = new Date(); tempdate.setDate(tempdate.getDate() + 14); return tempdate; })().toISOString();
        }
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdvertisement?advertisementId=${advertisementEvent.advertisement.id}`, {
            advertisement: values
        }, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = advertisements.findIndex(({ id }) => id === advertisementEvent.advertisement.id);
                    if (ix >= 0) {
                        advertisements[ix].title = values.title;
                        advertisements[ix].description = values.description;
                        setAdvertisements(Array.from(advertisements));
                    }
                    setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
                    setAdvertisementEditDialogOpen(false);
                }
            });
    }

    const updateComment = (values) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateComment?reviewId=${commentEvent.comment.id}`, {
            review: values
        }, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = comments.findIndex(({ id }) => id === commentEvent.comment.id);
                    if (ix >= 0) {
                        comments[ix].comment = values.comment;
                        comments[ix].point = values.point;
                        setComments(Array.from(comments));
                    }
                    setCommentEvent({ comment: null, type: null });
                    setOpenCommentDeletionAlertDialog(false);
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            {!user &&
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                </Box>
            }
            {user &&
                <Box>
                    <NotificationDialog dialogOpen={notificationDialogOpen} handleClose={() => setNotificationDialogOpen(false)} userId={user.id} />
                    <AlertDialogSlide
                        open={openAlertDialog} handleClose={() => handleAlertDialogClose("user")}
                        clickPositiveAnswer={() => clickPositiveAnswer("user")} clickNegativeAnswer={clickNegativeAnswer}
                        positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                        title={"Bu kullanıcıyı silmek istediğinize emin misiniz?"}
                        description={"Bu kullanıcı ile ilişkili tüm veriler silinecektir. (mesajlar, ilanlar, değerlendirmeler vb.)"}
                    />
                    {
                        advertisementEvent?.advertisement && advertisementEvent.type === "delete" &&
                        <AlertDialogSlide
                            open={openAdvertisementDeletionAlertDialog} handleClose={() => {
                                handleAlertDialogClose("advertisement");
                                setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
                            }}
                            clickPositiveAnswer={() => clickPositiveAnswer("advertisement")} clickNegativeAnswer={clickNegativeAnswer}
                            positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                            title={"Bu ilanı silmek istediğinize emin misiniz?"}
                            description={"Bu ilan ile ilişkili tüm veriler silinecektir. (mesajlar, şikayetler vb.)"}
                        />
                    }
                    {
                        commentEvent?.comment && commentEvent.type === "delete" &&
                        <AlertDialogSlide
                            open={openCommentDeletionAlertDialog} handleClose={() => {
                                handleAlertDialogClose("comment");
                                setCommentEvent({ comment: null, type: null });
                            }}
                            clickPositiveAnswer={() => clickPositiveAnswer("comment")} clickNegativeAnswer={clickNegativeAnswer}
                            positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                            title={"Bu yorumu silmek istediğinize emin misiniz?"}
                            description={"Bu yorum ile ilişkili tüm veriler silinecektir. (puanlar vb.)"}
                        />
                    }
                    {
                        advertisementEvent?.advertisement && advertisementEvent.type === "info" && advertisementEvent.fetched && <AdvertisementDetailsDialog
                            advertisement={advertisementEvent.advertisement}
                            dialogOpen={advertisementDetailsDialogOpen}
                            handleClose={() => advertisementDialogHandleClose("info")}
                        />
                    }
                    {
                        advertisementEvent?.advertisement && advertisementEvent.type === "edit" && advertisementEvent.fetched && <AdvertisementEditDialog
                            advertisement={advertisementEvent.advertisement}
                            dialogOpen={advertisementEditDialogOpen}
                            handleClose={() => advertisementDialogHandleClose("edit")}
                            handleFormSubmit={updateAdvertisement}
                        />
                    }
                    {
                        commentEvent?.comment && commentEvent.type === "edit" && <CommentEditDialog
                            comment={commentEvent.comment}
                            dialogOpen={commentEditDialogOpen}
                            handleClose={commentEditDialogHandleClose}
                            handleFormSubmit={updateComment}
                        />
                    }
                    <Box display="flex">
                        <Box display="flex">
                            <IconButton style={{ width: "40px", height: "40px", marginRight: "5px" }}
                                onClick={() => {
                                    window.history.back();
                                }}>
                                <ArrowBackIosNewOutlinedIcon />
                            </IconButton>
                            <Box display="flex" mr="5px">
                                <img
                                    alt="profile-user"
                                    src={user.profile_photo_link}
                                    width="40px"
                                    height="40px"
                                    style={{ cursor: "pointer", borderRadius: "50%" }} />
                            </Box>
                            <Box display="flex">
                                <Header title={user.name + " " + user.surname + " - " + (getUserAge(user.birth_date))} subtitle={""} />
                            </Box>
                        </Box>

                        <Box display="flex" gap="20px" mb="30px" ml="20px" alignItems="center">
                            <Button variant="contained" color='info' onClick={() => setNotificationDialogOpen(true)}>
                                Bildirim Gönder
                            </Button>
                        </Box>

                        <Box display="flex" gap="20px" mb="30px" ml="20px" alignItems="center">
                            <Button variant="contained" style={{ background: colors.redAccent[500] }} onClick={() => setOpenAlertDialog(true)}>
                                Sil
                            </Button>
                        </Box>

                    </Box>
                    <Box mx="30px" pt="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Kayıt Tarihi</b> <hr />{new Date(user.created_date).toLocaleTimeString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Eposta Adresi</b> <hr />{user.email}</Typography>
                        {user.phone_number && <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Telefon numarası</b> <hr />{user.phone_number}</Typography>}
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Doğum Tarihi</b> <hr />{new Date(user.birth_date).toLocaleString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Cinsiyet</b> <hr />{user.gender == 0 ? "Erkek" : user.gender == 1 ? "Kadın" : "Gizli"}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Ülke</b> <hr />{user.country}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Şehir</b> <hr />{user.city}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>İlçe</b> <hr />{user.county}</Typography>
                    </Box>
                    <Box m="30px 20px 0 20px" pb="30px">
                        <Accordion disabled={advertisements.length < 1}>
                            <AccordionSummary id='advertisement-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>İlanlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InfiniteScroll
                                    dataLength={advertisements.length}
                                    next={() => fetchAdvertisements(advertisements.length)}
                                    hasMore={hasMoreAdvertisements}
                                    loader={<h4>Yükleniyor...</h4>}
                                    height={510}
                                >
                                    <Box m="10px 0 0 0" maxHeight="500px" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                                        {advertisements.map((advertisement) => <AdvertisementBox
                                            key={advertisement.id}
                                            advertisement={advertisement}
                                            onInfoButtonClick={() => setAdvertisementEvent({ advertisement: advertisement, type: "info", fetched: false })}
                                            onEditButtonClick={() => setAdvertisementEvent({ advertisement: advertisement, type: "edit", fetched: false })}
                                            onDeleteButtonClick={() => setAdvertisementEvent({ advertisement: advertisement, type: "delete", fetched: false })}
                                        />)}
                                    </Box>
                                </InfiniteScroll>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={comments.length < 1}>
                            <AccordionSummary id='comments-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Yaptığı Yorumlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InfiniteScroll
                                    dataLength={comments.length}
                                    next={() => fetchComments(comments.length)}
                                    hasMore={hasMoreComments}
                                    loader={<h4>Yükleniyor...</h4>}
                                    height={510}
                                >
                                    <Box m="10px 0 0 0" maxHeight="500px" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                                        {comments.map((comment) => <CommentBox
                                            key={comment.id}
                                            comment={comment}
                                            onEditButtonClick={() => setCommentEvent({ comment: comment, type: "edit" })}
                                            onDeleteButtonClick={() => setCommentEvent({ comment: comment, type: "delete" })}
                                        />)}
                                    </Box>
                                </InfiniteScroll>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default UserDetailsPage;