import React, { useContext, useEffect, useState } from 'react'
import AdvertisementBox from '../Advertisement/AdvertisementBox';
import axios from 'axios';
import { AdminContext } from '../../context/AdminContext';
import AlertDialogSlide from '../AlertDialogSlide';
import AdvertisementEditDialog from '../Advertisement/AdvertisementEditDialog';
import AdvertisementDetailsDialog from '../Advertisement/AdvertisementDetailsDialog';
import { Box } from '@mui/material';
import { tokens } from '../../theme';
import ReportBox from './ReportBox';

function AdvertisementReportBox({ report, onDeleteButtonClick, removeReport, onAdvertisementRemoved }) {
    const { token } = useContext(AdminContext);
    const colors = tokens();
    const advertisement = {
        id: report.advertisement_id,
        content_link: report.advertisement_content_link,
        title: report.advertisement_title,
        description: report.advertisement_description,
        is_photo: report.advertisement_content_is_photo
    };
    const [advertisementEvent, setAdvertisementEvent] = useState({ advertisement: null, type: null, fetched: false });
    const [advertisementDetailsDialogOpen, setAdvertisementDetailsDialogOpen] = useState(false);
    const [advertisementEditDialogOpen, setAdvertisementEditDialogOpen] = useState(false);
    const [openAdvertisementDeletionAlertDialog, setOpenAdvertisementDeletionAlertDialog] = useState(false);
    const handleAdvertisementDeletionAlertDialogClose = (type) => {
        setOpenAdvertisementDeletionAlertDialog(false);
    }
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteAdvertisement?advertisementId=${advertisementEvent.advertisement.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
                setOpenAdvertisementDeletionAlertDialog(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    onAdvertisementRemoved(report);
                }
            });
    }
    const clickNegativeAnswer = () => { }

    useEffect(() => {
        const fetchAdvertisementDeatils = () => {
            axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getAdvertisementWithDetails?advertisementId=${advertisementEvent.advertisement.id}`)
                .catch((err) => {
                    console.log("err: " + err);
                    setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        setAdvertisementEvent((prev) => { return { advertisement: response.data.advertisement, type: prev.type, fetched: true } });
                    }
                });
        }
        if (advertisementEvent?.advertisement && advertisementEvent.type === "info") {
            if (!advertisementEvent.fetched) {
                fetchAdvertisementDeatils();
            } else {
                setAdvertisementDetailsDialogOpen(true);
            }
        } else if (advertisementEvent?.advertisement && advertisementEvent.type === "delete") {
            setOpenAdvertisementDeletionAlertDialog(true);
        } else if (advertisementEvent?.advertisement && advertisementEvent.type === "edit") {
            if (!advertisementEvent.fetched) {
                fetchAdvertisementDeatils();
            } else {
                setAdvertisementEditDialogOpen(true);
            }
        }
    }, [advertisementEvent]);

    const advertisementDialogHandleClose = (type) => {
        setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
        if (type === "info") {
            setAdvertisementDetailsDialogOpen(false);
        } else if (type === "edit") {
            setAdvertisementEditDialogOpen(false);
        }
    }

    const updateAdvertisement = async (values) => {
        values = { ...values, expiration_date: values.expiration_date.toISOString() }
        if (values?.category_2_id === "") values.category_2_id = null;
        if (values?.advertisement_contents?.length !== advertisementEvent.advertisement.advertisement_contents.length) {
            console.log("values.advertisement_contents: " + values.advertisement_contents);
            var advertisementContents = [];
            values.advertisement_contents.forEach((content, index) => {
                advertisementContents.push({ content_path: content.content_path, is_photo: content.is_photo, position: index, advertisement_id: advertisementEvent.advertisement.id });
            });
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdvertisementContents?advertisementId=${advertisementEvent.advertisement.id}`, {
                advertisementContents: advertisementContents
            }, {
                headers: {
                    "token": token
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {

                    }
                });
        }
        delete values.advertisement_contents;
        if (values.is_active === 1 && advertisementEvent.advertisement.is_active === 0) {
            values.expiration_date = (() => { var tempdate = new Date(); tempdate.setDate(tempdate.getDate() + 14); return tempdate; })().toISOString();
        }
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdvertisement?advertisementId=${advertisementEvent.advertisement.id}`, {
            advertisement: values
        }, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
                    setAdvertisementEditDialogOpen(false);
                    removeReport(report);
                }
            });
    }

    return (
        <Box sx={{ gridColumn: "span 1" }}>
            {
                advertisementEvent?.advertisement && advertisementEvent.type === "delete" &&
                <AlertDialogSlide
                    open={openAdvertisementDeletionAlertDialog} handleClose={() => {
                        handleAdvertisementDeletionAlertDialogClose();
                        setAdvertisementEvent({ advertisement: null, type: null, fetched: false });
                    }}
                    clickPositiveAnswer={() => clickPositiveAnswer()} clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                    title={"Bu ilanı silmek istediğinize emin misiniz?"}
                    description={"Bu ilan ile ilişkili tüm veriler silinecektir. (mesajlar, şikayetler vb.)"}
                />
            }
            {
                advertisementEvent?.advertisement && advertisementEvent.type === "info" && advertisementEvent.fetched &&
                <AdvertisementDetailsDialog
                    advertisement={advertisementEvent.advertisement}
                    dialogOpen={advertisementDetailsDialogOpen}
                    handleClose={() => advertisementDialogHandleClose("info")}
                />
            }
            {
                advertisementEvent?.advertisement && advertisementEvent.type === "edit" && advertisementEvent.fetched &&
                <AdvertisementEditDialog
                    advertisement={advertisementEvent.advertisement}
                    forReport={true}
                    dialogOpen={advertisementEditDialogOpen}
                    handleClose={() => advertisementDialogHandleClose("edit")}
                    handleFormSubmit={updateAdvertisement}
                />
            }
            <ReportBox report={report} onDeleteButtonClick={onDeleteButtonClick} />
            <AdvertisementBox
                advertisement={advertisement}
                forReport={true}
                onInfoButtonClick={() => setAdvertisementEvent({ advertisement: advertisement, type: "info", fetched: false })}
                onEditButtonClick={() => setAdvertisementEvent({ advertisement: advertisement, type: "edit", fetched: false })}
                onDeleteButtonClick={() => setAdvertisementEvent({ advertisement: advertisement, type: "delete", fetched: false })}
            />
        </Box>

    )
}

export default AdvertisementReportBox