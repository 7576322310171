import { Box, Button } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import FaqDialog from './FaqDialog';
import FaqBox from './FaqBox';

function FaqPage() {
    const { token } = useContext(AdminContext);
    const [faqs, setFaqs] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [maxPosition, setMaxPosition] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [faqEvent, setFaqEvent] = useState({ faq: null, type: null });
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    useEffect(() => {
        fetchFaqs();
    }, [startIndex]);

    useEffect(() => {
        if ((faqEvent?.faq && faqEvent?.type === "edit") || (!faqEvent?.faq && faqEvent?.type === "add")) {
            setEditDialogOpen(true);
        } else if (faqEvent?.faq && faqEvent?.type === "delete") {
            setRemoveDialogOpen(true);
        } else if (faqEvent?.faq && faqEvent?.type === "changePosition") {
            changeFaqPosition(faqEvent.faq.id, faqEvent.faq.currentPosition, faqEvent.faq.newPosition);
        }
    }, [faqEvent]);

    const fetchFaqs = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getFaqs?startIndex=${startIndex}&limit=20`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
                setFaqs([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.faqs && response.data.faqs.length === 20);
                    response.data.maxPosition = response.data.maxPosition === 0 ? 1 : response.data.maxPosition;
                    setMaxPosition(response.data.maxPosition);
                    if (startIndex === 0) {
                        setFaqs(response.data.faqs);
                    } else {
                        setFaqs((prev) => [...prev, ...response.data.faqs]);
                    }
                }
            });
    }

    const fetchFaqToUpdate = (faq) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getFaqToUpdate?faqId=${faq.id}`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setFaqEvent({ faq: response.data.faq, type: "edit" });
                }
            });
    }

    const handleFaqFormSubmit = async (faq) => {
        if (faqEvent.type === "add") {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createFaqWithTranslations`, {
                ...faq
            }, { headers: { token: token } })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        if (startIndex === 0) {
                            fetchFaqs();
                        } else {
                            setStartIndex(0);
                        }
                        setFaqEvent({ faq: null, type: null });
                        setEditDialogOpen(false);
                    }
                });

        } else if (faqEvent.type === "edit") {
            var faqIx = faqs.findIndex(c => c.id === faqEvent.faq.id);

            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateFaqWithTranslations?faqId=${faqEvent.faq.id}`, {
                faq: faq
            }, { headers: { token: token } })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        faqs[faqIx] = faq;
                        faqs[faqIx].id = faqEvent.faq.id;
                        setFaqs(Array.from(faqs));
                        setFaqEvent({ faq: null, type: null });
                        setEditDialogOpen(false);
                    }
                });
        }
    }

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    }
    const clickPositiveAnswer = () => {
        removeFaq(faqEvent.faq);
    }
    const clickNegativeAnswer = () => {
    }

    const removeFaq = (faq) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteFaq?faqId=${faq.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setFaqEvent({ faq: null, type: null });
                setRemoveDialogOpen(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (response && response.data.success === true && response.data.status === 201) {
                        var ix = faqs.findIndex(({ id }) => id === faq.id);
                        faqs.splice(ix, 1);
                        let newFaqs = Array.from(faqs);
                        for (let i = ix; i < newFaqs.length; i++) {
                            newFaqs[i].position = newFaqs[i].position - 1;
                        }
                        setFaqs(newFaqs);
                        setMaxPosition(maxPosition - 1);
                        setFaqEvent({ faq: null, type: null });
                        setRemoveDialogOpen(false);
                    }
                }
            });
    }

    const changeFaqPosition = (faqId, currentPosition, newPosition) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/changeFaqPosition?faqId=${faqId}&currentPosition=${currentPosition}&newPosition=${newPosition}`, {}, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ixCurrent = faqs.findIndex(({ position }) => position === currentPosition);
                    var ixNew = faqs.findIndex(({ position }) => position === newPosition);
                    let newFaqs = Array.from(faqs);
                    newFaqs[ixCurrent].position = newPosition;
                    newFaqs[ixNew].position = currentPosition;
                    let tempFaq = newFaqs[ixCurrent];
                    newFaqs[ixCurrent] = newFaqs[ixNew];
                    newFaqs[ixNew] = tempFaq;
                    setFaqs(newFaqs);
                    setFaqEvent({ faq: null, type: null });
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            {
                faqEvent.faq && faqEvent.type === "delete" &&
                <AlertDialogSlide
                    open={removeDialogOpen} handleClose={() => {
                        handleRemoveDialogClose();
                        setFaqEvent({ faq: null, type: null })
                    }}
                    clickPositiveAnswer={() => clickPositiveAnswer()} clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                    title={"Bu soruyu silmek istediğinize emin misiniz?"}
                    description={"İlgili veri silinecektir ve bu işlem geri alınamaz."}
                />
            }
            {
                ((faqEvent.faq && faqEvent.type === "edit") || (!faqEvent.faq && faqEvent.type === "add")) &&
                <FaqDialog
                    dialogOpen={editDialogOpen}
                    handleClose={() => {
                        setEditDialogOpen(false);
                        setFaqEvent({ faq: null, type: null });
                    }}
                    faq={faqEvent.faq}
                    handleFormSubmit={handleFaqFormSubmit}
                />
            }
            <Box display="flex" alignItems="center" gap="30px">
                <Header title="Sıkça Sorulan Sorular" subtitle="Bu sayfadan SSS'ı görüntüleyebilir ve işlem yapabilirsiniz." />
                <Button variant="contained" color="success" onClick={() => setFaqEvent({ faq: null, type: "add" })}>Yeni SSS Ekle</Button>
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={faqs.length}
                    next={() => {
                        setStartIndex(faqs.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Tüm sıkça sorulan soruları görüntülüyorsunuz.</b>
                        </p>
                    }
                >
                    <Box m="10px 0 0 0" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                        {faqs.map(faq =>
                            <FaqBox
                                key={faq.id}
                                faq={faq}
                                onEditButtonClick={() => fetchFaqToUpdate(faq)}
                                onDeleteButtonClick={() => setFaqEvent({ faq: faq, type: "delete" })}
                                onChangePositionButtonClick={(faqId, currentPosition, newPosition) => setFaqEvent({ faq: { id: faqId, currentPosition: currentPosition, newPosition: newPosition }, type: "changePosition" })}
                                maxPosition={maxPosition}
                            />
                        )}
                    </Box>
                </InfiniteScroll>
            </Box>

        </Box>
    )
}

export default FaqPage