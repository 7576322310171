import React, { useContext } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { AdminContext } from '../../context/AdminContext';

function NotificationDialog({ dialogOpen, userId, handleClose }) {
    const { token } = useContext(AdminContext);

    var initialValues = {
        message: "",
        ...(userId ? {} : { language: "en" })
    }

    var validationObject = {
        message: yup.string().required("zorunlu"),
        ...(userId ? {} : { language: yup.string().required("zorunlu") }),
    }

    const notificationSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/${userId ? `sendPushNotificationToUser?userId=${userId}` : `sendPushNotificationToTopic?topic=${values.language}`}`, {
            message: values.message
        }, { headers: { token: token } }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                handleClose();
            }
        });

    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                <Typography variant='h3'>
                    Bildirim
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={notificationSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mb="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        name={"message"}
                                        value={values.message}
                                        error={!!touched.message && !!errors.message}
                                        helperText={touched.message && errors.message}
                                        sx={{ gridColumn: "span 4", marginTop: "10px" }}
                                    />

                                    {!userId &&
                                        <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", marginTop: "10px" }}>
                                            <InputLabel id="filter-label">Dil</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Dil"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"language"}
                                                value={values.language}
                                                error={!!touched.language && !!errors.language}
                                            >
                                                <MenuItem value={"en"}>İngilizce</MenuItem>
                                                <MenuItem value={"de"}>Almanca</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                </Box>
                                <Box display="flex" justifyContent="end" mt="30px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default NotificationDialog