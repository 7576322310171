import { Box, Button } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import CategoryBox from './CategoryBox';
import CategoryDialog from './CategoryDialog';

function CategoriesPage() {
    const { token } = useContext(AdminContext);
    const [categories, setCategories] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [categoryEvent, setCategoryEvent] = useState({ category: null, type: null });
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    useEffect(() => {
        fetchCategories();
    }, [startIndex]);

    useEffect(() => {
        if ((categoryEvent?.category && categoryEvent?.type === "edit") || (!categoryEvent?.category && categoryEvent?.type === "add")) {
            setEditDialogOpen(true);
        }
        else if (categoryEvent?.category && categoryEvent?.type === "delete") {
            setRemoveDialogOpen(true);
        }
    }, [categoryEvent]);

    const fetchCategories = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCategories?startIndex=${startIndex}&limit=20`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
                setCategories([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.categories && response.data.categories.length === 20);
                    if (startIndex === 0) {
                        setCategories(response.data.categories);
                    } else {
                        setCategories((prev) => [...prev, ...response.data.categories]);
                    }
                }
            });
    }

    const fetchCategoryToUpdate = (category) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCategoryToUpdate?categoryId=${category.id}`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCategoryEvent({ category: response.data.category, type: "edit" });
                }
            });
    }

    const handleCategoryFormSubmit = async (category) => {
        if (categoryEvent.type === "add") {
            if (category.content) {
                await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadCategoryContent`, {
                    categoryContent: category.content
                }, {
                    headers: {
                        token: token,
                        "content-type": "multipart/form-data"
                    }
                })
                    .catch((err) => {
                        console.log("err: " + err);
                    })
                    .then((response) => {
                        if (response && response.data.success === true && response.data.status === 201) {
                            category.content_path = response.data.contentPath;
                        }
                    });
            }

            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createCategoryWithTranslations`, {
                ...category
            }, { headers: { token: token } })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        if (startIndex === 0) {
                            fetchCategories();
                        } else {
                            setStartIndex(0);
                        }
                        setCategoryEvent({ category: null, type: null });
                        setEditDialogOpen(false);
                    }
                });

        } else if (categoryEvent.type === "edit") {
            var categoryIx = categories.findIndex(c => c.id === categoryEvent.category.id);
            var contentLink;
            if (category.content) {
                await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadCategoryContent`, {
                    categoryContent: category.content
                }, {
                    headers: {
                        token: token,
                        "content-type": "multipart/form-data"
                    }
                })
                    .catch((err) => {
                        console.log("err: " + err);
                    })
                    .then((response) => {
                        if (response && response.data.success === true && response.data.status === 201) {
                            category.content_path = response.data.contentPath;
                            contentLink = category.content_link.slice(0, category.content_link.indexOf("/images") + 1) + response.data.contentPath;
                        }
                    });
            }

            var categoryToUpdate = { ...category };
            delete categoryToUpdate.content;
            delete categoryToUpdate.content_link;

            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateCategoryWithTranslations?categoryId=${categoryEvent.category.id}`, {
                category: categoryToUpdate
            }, { headers: { token: token } })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        categories[categoryIx] = categoryToUpdate;
                        categories[categoryIx].id = categoryEvent.category.id;
                        if (contentLink) {
                            categories[categoryIx].content_link = contentLink;
                        } else {
                            categories[categoryIx].content_link = categoryEvent.category.content_link;
                        }
                        setCategories(Array.from(categories));
                        setCategoryEvent({ category: null, type: null });
                        setEditDialogOpen(false);
                    }
                });
        }
    }

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    }
    const clickPositiveAnswer = () => {
        removeCategory(categoryEvent.category);
    }
    const clickNegativeAnswer = () => {
    }

    const removeCategory = (category) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteCategory?categoryId=${category.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setCategoryEvent({ category: null, type: null })
                setRemoveDialogOpen(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = categories.findIndex(({ id }) => id === category.id);
                    if (ix >= 0) {
                        categories.splice(ix, 1);
                        setCategories(Array.from(categories));
                    }
                    setCategoryEvent({ category: null, type: null });
                    setRemoveDialogOpen(false);
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            {
                categoryEvent.category && categoryEvent.type === "delete" &&
                <AlertDialogSlide
                    open={removeDialogOpen} handleClose={() => {
                        handleRemoveDialogClose();
                        setCategoryEvent({ category: null, type: null })
                    }}
                    clickPositiveAnswer={() => clickPositiveAnswer()} clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                    title={"Bu kategoriyi silmek istediğinize emin misiniz?"}
                    description={"Bu kategori ve ilişkili tüm veriler silinecektir. (Seçilen kullanıcı kategorisi, seçilen ilan kategorisi vb.)"}
                />
            }
            {
                ((categoryEvent.category && categoryEvent.type === "edit") || (!categoryEvent.category && categoryEvent.type === "add")) &&
                <CategoryDialog
                    dialogOpen={editDialogOpen}
                    handleClose={() => {
                        setEditDialogOpen(false);
                        setCategoryEvent({ category: null, type: null });
                    }}
                    category={categoryEvent.category}
                    handleFormSubmit={handleCategoryFormSubmit}
                />
            }
            <Box display="flex" alignItems="center" gap="30px">
                <Header title="Kategoriler" subtitle="Bu sayfadan kategorileri görüntüleyebilir ve işlem yapabilirsiniz." />
                <Button variant="contained" color="success" onClick={() => setCategoryEvent({ category: null, type: "add" })}>Yeni Kategori Ekle</Button>
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={categories.length}
                    next={() => {
                        setStartIndex(categories.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Tüm kategorileri görüntülüyorsunuz.</b>
                        </p>
                    }
                >
                    <Box m="10px 0 0 0" display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                        {categories.map(category =>
                            <CategoryBox
                                key={category.id}
                                category={category}
                                onEditButtonClick={() => {
                                    fetchCategoryToUpdate(category);
                                }}
                                onDeleteButtonClick={() => setCategoryEvent({ category: category, type: "delete" })}
                            />
                        )}
                    </Box>
                </InfiniteScroll>
            </Box>

        </Box>
    )
}

export default CategoriesPage