import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

function CategoryDialog({ category, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        name: category?.name ? category?.name : "",
        content: null,
        content_link: category?.content_link ? category?.content_link : "",
        translations: category?.translations && category?.translations.length > 0 ? category?.translations : [
            { language: "de", translation: "" }
        ]
    };

    var validationObject = {
        name: yup.string().required("zorunlu"),
        content: yup.mixed().nullable().when('content_link', {
            is: (content_link) => (!content_link || content_link === ""),
            then: () => yup.string().nonNullable("").required('zorunlu')
        }),
        content_link: yup.string(),
        translations: yup.array().of(yup.object().shape({
            language: yup.string().required("zorunlu"),
            translation: yup.string().required("zorunlu"),
        }).required("zorunlu")).required("zorunlu")
    }

    const categorySchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {category ? "Kategori Düzenle" : "Yeni Kategori Ekle"}
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={categorySchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Typography variant="h6">Kategori Görseli</Typography>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                        {(values.content || (values.content_link && values.content_link !== "")) && <img alt='category' width="100px" src={values.content ? URL.createObjectURL(values.content) : values.content_link} />}
                                        <input id="content" name="content" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("content", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kategori Adı* (en)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 1", my: "20px" }}
                                    />
                                    {values.translations.map((translation, i) =>

                                        <TextField
                                            key={`translation-${i}`}
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={`Kategori Adı* (${translation.language})`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + i + "].translation"}
                                            value={values.translations[i].translation}
                                            error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.translation}
                                            helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.translation}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                    )}
                                </Box>

                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default CategoryDialog