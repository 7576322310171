import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { tokens } from './../theme';

function BasicUserBox({ user }) {
    const colors = tokens();
    return (
        <Box display="flex" justifyContent="space-between" sx={{ border: "1px solid " + colors.grey[500], padding: "10px 15px 10px 15px", borderRadius: "0 0 20px 20px" }}>
            <Box >
                <Box display="flex" gap="10px" alignItems="center">
                    <Box component="img" src={user.profile_photo_link} alt={user.name} sx={{ objectFit: "cover", width: "50px", height: "50px", borderRadius: "25px" }} />
                    <Box>
                        <Typography variant="h6">Rapor Edilen</Typography>
                        <Typography variant="h4">{user.name} {user.surname}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Button variant="contained" color="info" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => { window.location.href = `/users/${user.id}`; }} >Kullanıyı Gör</Button>
            </Box>
        </Box>

    )
}

export default BasicUserBox