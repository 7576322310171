import React from 'react'
import { Box } from '@mui/material';
import ReportBox from './ReportBox';
import BasicUserBox from '../BasicUserBox';

function AdvertisementReportBox({ report, onDeleteButtonClick }) {

    const user = {
        id: report.user_id,
        profile_photo_link: report.reported_user_profile_photo_link,
        name: report.reported_user_name,
        surname: report.reported_user_surname
    };

    return (
        <Box sx={{ gridColumn: "span 1" }}>
            <ReportBox report={report} onDeleteButtonClick={onDeleteButtonClick} />
            <BasicUserBox user={user} />
        </Box>

    )
}

export default AdvertisementReportBox