import React from 'react'
import { Box, Dialog, DialogContent, Typography } from '@mui/material';

import { Close } from '@mui/icons-material';

function AdvertisementDetailsDialog({ advertisement, dialogOpen, handleClose }) {
    return (
        advertisement &&
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            maxWidth={"80%"}
            open={dialogOpen}
            onClose={handleClose}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Box
                    style={{
                        position: "absolute",
                        right: "20px",
                        top: "10px",
                        color: "black",
                        cursor: "pointer",
                        borderRadius: "50%",
                        padding: "8px"
                    }}
                    onClick={handleClose}
                >
                    <Close />
                </Box>
            </Box>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    İlan Detayı({advertisement.id})
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "0", paddingBottom: "45px" }}>
                        <Box style={{
                            position: "relative"
                        }}>
                            <Box borderRadius="12px" component={advertisement.advertisement_contents[0].is_photo ? "img" : "video"} controls src={advertisement.advertisement_contents[0].content_link} alt="advertisement-big" width={800} />

                            {
                                advertisement.is_fully_closed ?
                                    <Box
                                        style={{
                                            background: "red",
                                            position: "absolute",
                                            left: "10px",
                                            top: "10px",
                                            padding: "4px 12px",
                                            borderRadius: "12px"
                                        }}
                                    >
                                        <p style={{ fontSize: "16px", margin: 0, color: "white" }}>{`Kapatılmış: ${advertisement.closure_reason == 0 ? "Anlaştım." : advertisement.closure_reason == 1 ? "Sadece temizlik yapıyorum." : advertisement.closure_reason == 2 ? "Başka yerde hallettim." : "Vazgeçtim."}`}</p>
                                    </Box>
                                    :
                                    !(advertisement.is_active && new Date(advertisement.expiration_date) > new Date()) ?
                                        <Box
                                            style={{
                                                background: "red",
                                                position: "absolute",
                                                left: "10px",
                                                top: "10px",
                                                padding: "4px 12px",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <p style={{ fontSize: "16px", margin: 0, color: "white" }}>{`Pasif (${new Date(advertisement.expiration_date) > new Date() ? "Süresi geçmiş." : ""})`}</p>
                                        </Box>
                                        :
                                        advertisement.is_featured && new Date(advertisement.featured_end_date) > new Date() ?
                                            <Box
                                                style={{
                                                    background: "#8C52FF",
                                                    position: "absolute",
                                                    left: "10px",
                                                    top: "10px",
                                                    padding: "4px 12px",
                                                    borderRadius: "12px"
                                                }}
                                            >
                                                <p style={{ fontSize: "16px", margin: 0, color: "white" }}>{`Acil İlan (Bitiş: ${(new Date(advertisement.featured_end_date)).toLocaleDateString() + " " + (new Date(advertisement.featured_end_date)).toLocaleTimeString()})`}</p>
                                            </Box>
                                            :
                                            ""
                            }
                        </Box>
                        <Box style={{ display: "grid", gridTemplateColumns: "repeat(3, minmax(0, 1fr))", gap: "16px", width: "800px", marginTop: "8px" }}>
                            {advertisement.advertisement_contents.map((content, index) => {
                                if (index !== 0) {
                                    return <Box
                                        key={content.id}
                                        component={content.is_photo ? "img" : "video"}
                                        controls
                                        borderRadius="12px"
                                        gridColumn={"span 1"}
                                        src={content.content_link}
                                        alt={`advertisement-sm-${index}`}
                                        width={255} />
                                } else {
                                    return ""
                                }
                            })}
                        </Box>
                        <Box style={{ width: "800px" }}>
                            <h1 style={{ fontSize: "24px", fontWeight: 900 }}>{advertisement.title}</h1>
                            <p style={{ fontSize: "16px" }}>{advertisement.description}</p>
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px"
                                }}
                            >
                                <Box style={{ display: "flex", gap: "4px" }} >
                                    <Box component="img" borderRadius="12px" src={advertisement.user_profile_photo_link} alt="user-profile" width={24} height={24} />
                                    <p style={{ fontSize: "16px", margin: 0 }}>{`${advertisement.user_name} ${advertisement.user_surname}`}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.country}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.city}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.county}</p>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                    mt: "20px"
                                }}
                            >
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{`Görüntülenme: ${advertisement.view_count}`}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{`Paylaşılma: ${advertisement.publish_count}`}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{`Favori: ${advertisement.fav_count}`}</p>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                    mt: "20px"
                                }}
                            >
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.category_1_name}</p>
                                </Box>
                                {advertisement.category_2_name &&
                                    <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                        <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.category_2_name}</p>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>

    )
}

export default AdvertisementDetailsDialog