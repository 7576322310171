import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { tokens } from '../../theme';

function AdvertisementBox({ advertisement, forReport, onInfoButtonClick, onEditButtonClick, onDeleteButtonClick }) {
    const colors = tokens();
    return (
        <Box display="flex" flexDirection="column" gap="20px" justifyContent="space-between" sx={{ gridColumn: "span 1", border: "1px solid " + colors.grey[500], ...(forReport ? { borderRadius: "0 0 20px 20px" } : { borderRadius: "20px" }) }}>
            <Box>
                <Box>
                    <Box component={advertisement.is_photo ? "img" : "video"} controls src={advertisement.content_link} alt={advertisement.title} sx={{ objectFit: "cover", width: "100%", height: "200px", ...(forReport ? { borderRadius: "0" } : { borderRadius: "20px 20px 0 0" }) }} />
                </Box>
                <Box padding="10px" mt="10px">
                    <Typography variant='h3'>{advertisement.title}</Typography>
                    <Typography mt={"10px"}>{advertisement.description}</Typography>
                </Box>
            </Box>
            <Box display="flex" gap="10px" padding="10px">
                <Button variant="contained" color="info" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => onInfoButtonClick()} >{forReport ? "İlan Detayı" : "Detay"}</Button>
                <Button variant="contained" color="success" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => onEditButtonClick()}>{forReport ? "İlanı Düzenle" : "Düzenle"}</Button>
                <Button variant="contained" color="error" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => onDeleteButtonClick()} >{forReport ? "İlanı Sil" : "Sil"}</Button>
            </Box>
        </Box>
    )
}

export default AdvertisementBox