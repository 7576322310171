import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { tokens } from '../../theme';

function CommentBox({ comment, onEditButtonClick, onDeleteButtonClick }) {
    const colors = tokens();
    return (
        <Box display="flex" flexDirection="column" gap="20px" justifyContent="space-between" sx={{ gridColumn: "span 1", border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box padding="10px">
                <Box display="flex" gap="10px" alignItems="center">
                    <Box component="img" src={comment.user_profile_photo_link} alt={comment.user_name} sx={{ objectFit: "cover", width: "50px", height: "50px", borderRadius: "25px" }} />
                    <Typography variant="h3">{comment.user_name} {comment.user_surname}</Typography>
                </Box>
                <Box mt="10px">
                    <Typography variant='h5'>Verilen Puan: {comment.point}</Typography>
                </Box>
            </Box>
            <Box component="hr" height="1px" width="100%" sx={{ background: "black", border: "0px", margin: "0px" }} />
            <Box padding="10px">
                <Typography variant='h5'>{comment.comment}</Typography>
            </Box>
            <Box display="flex" gap="10px" padding="10px">
                <Button variant="contained" color="success" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => onEditButtonClick()}>Düzenle</Button>
                <Button variant="contained" color="error" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => onDeleteButtonClick()} >Sil</Button>
            </Box>
        </Box>
    )
}

export default CommentBox